<!--  -->
<template>
  <div class="GetBackPassword">
    <div class="head">
      <h1>找回密码</h1>
      <span></span>
    </div>
    <van-form
      @submit="onSubmit"
      class="login_form flexcolumn"
      style="margin-top: 30px"
    >
      <div class="login_title"></div>
      </van-popup>
      <van-field
        v-model="form.phonenumber"
        maxlength="11"
        name="手机号"
        label=""
        type="Number"
        :label-width="30"
        placeholder="请输入手机号"
        :rules="[{ validator: validator, message: '' }]"
      />
      <van-field
        v-model="form.pwd"
        name="手机号"
        label=""
        type="password"
        :label-width="30"
        placeholder="请输入新密码"
        :rules="[{ required: true, message: '' }]"
      />
      <div class="flex" style="width: 100%">
        <van-field
          v-model="form.smscode"
          maxlength="11"
          name="验证码"
          label=""
          type="Number"
          :label-width="30"
          placeholder="请输入验证码"
          :rules="[{ required: true, message: '' }]"
        />
        <div
          style="width: 50%; color: #1989fa; text-align: center"
          @click="
            verification.name == '获取验证码'
              ? verificationClick()
              : $Toast(`请等待${verification.sec}秒后操作`)
          "
        >
          {{ verification.name }}
        </div>
      </div>
      <div
        style="width: 100%; text-align: right; height: 50px; line-height: 50px"
        
      >
        <span @click="$router.push('/')">去登录</span>
      </div>
      <div style="width: 100%">
        <van-button
          round
          block
          type="info"
          class="myButton"
          native-type="submit"
          style="box-shadow: 0 6px 10px 2px rgba(0, 119, 255, 0.3)"
          >确认</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { ForgetPwd, SendSMSAsync } from "@/api";
export default {
  name: "GetBackPassword",
  components: {},
  data() {
    return {
      form: {
        pwd: "",
        phonenumber: "",
        smscode: "",
      },
      verification: {
        name: "获取验证码",
        sec: 300,
      },
      timeFc: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    async verificationClick() {
      if (/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.form.phonenumber)) {
        let res = await SendSMSAsync({ phonenumber: this.form.phonenumber });
        this.$Toast(res.Message);
        if (res.Code != 0) {
          var timeFc = setInterval(() => {
            this.verification.sec -= 1;
            this.verification.name = this.verification.sec + "s";
            if (this.verification.sec <= 0) {
              clearInterval(timeFc);
              this.verification = this.$options.data().verification;
            }
          }, 1000);
        }
      } else this.$Toast("请输入正确的手机号");
    },
    validator(val) {
      return /^[1][3,4,5,7,8,9][0-9]{9}$/.test(val);
    },
    async onSubmit() {
      let res = await ForgetPwd({ ...this.form });
      this.$Toast(res.Message);
      this.form = this.$options.data().form;
    },
  },
  created() {
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.GetBackPassword {
  width: 100%;
  padding: 200px 60px;
  min-height: 100vh;
  background-color: white;
  h1 {
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 8px;
  }
  .head {
    span {
      width: 45px;
      display: block;
      height: 6px;
      background-color: #000;
    }
  }
  /deep/ .van-cell {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
</style>